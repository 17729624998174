import React from "react"
import { Box } from "@chakra-ui/react"
import { COLORS } from "../constants/constants"

const NotFound = () => (
  <Box
    bg="#F7F7F9"
    py={"7"}
    display={"flex"}
    flexDirection={"column"}
    justifyContent={"center"}
    alignItems={"center"}
    marginInlineStart={0}
    flexGrow={1}
  >
    <h1 style={{ fontWeight: "bold" }}>404 - Page Not Found</h1>

    <p>Sorry, the page you are looking for does not exist.</p>

    <div
      style={{
        margin: "12px 0",
        borderRadius: "12px",
        backgroundColor: COLORS.red,
        color: "#fff",
        width: "fit-content",
        padding: "8px 16px",
      }}
    >
      <a href="/">Go Home</a>
    </div>
  </Box>
)

export default NotFound
