import React, { useState } from "react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Text,
  Divider,
  Textarea,
  Box,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { COLORS } from "../constants/constants"

const options = [
  { id: 1, value: "found_flights" },
  { id: 2, value: "competing_service" },
  { id: 3, value: "not_enough_destinations" },
  { id: 4, value: "too_expensive" },
  { id: 5, value: "something_else" },
]

const buttonsStyles = {
  button: {
    width: "122px",
  },
  p: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "uppercase",
  },
}

const alertDialogBodyStyles = {
  p: { color: COLORS.black },
}

const FeedbackPopup = ({
  isOpen,
  onClose,
  onConfirm,
  isProcessing = false,
}) => {
  const { t } = useTranslation()
  const [checkedItems, setCheckedItems] = useState([])
  const [additionalFeedback, setAdditionalFeedback] = useState("")

  const handleCheckboxChange = (value) => {
    setCheckedItems(value)
  }

  const handleTextareaChange = (event) => {
    setAdditionalFeedback(event.target.value)
  }

  const isConfirmDisabled =
    checkedItems.length === 0 ||
    isProcessing ||
    (checkedItems.includes("something_else") && !additionalFeedback.trim())

  const handleConfirm = () => {
    const formData = {
      checked_items: checkedItems,
      feedback_text: additionalFeedback,
    }
    onConfirm({ formData })
  }

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent maxWidth="lg">
          <AlertDialogBody padding={6} sx={alertDialogBodyStyles}>
            <Text fontSize={"24px"} lineHeight={"30px"} fontWeight={800}>
              {t("subscription.cancellation.feedback.header")}
            </Text>

            <Text fontSize={"14px"} lineHeight={"20px"} fontWeight={600} mt={4}>
              {t("subscription.cancellation.feedback.text")}
            </Text>

            <Flex flexDirection="column" mt={4}>
              <Flex flexDirection={"column"}>
                <CheckboxGroup
                  colorScheme="red"
                  onChange={handleCheckboxChange}
                >
                  {options.map(({ id, value }) => (
                    <Box key={id}>
                      <Checkbox
                        value={value}
                        mb={value === "something_else" ? 0 : 3}
                      >
                        <Text
                          as={"span"}
                          fontWeight={600}
                          fontSize={"14px"}
                          lineHeight={"20px"}
                          color={COLORS.black}
                        >
                          {t(
                            `subscription.cancellation.feedback.options.${id}`
                          )}
                        </Text>
                      </Checkbox>

                      {value === "something_else" && (
                        <Flex flexDirection="column" mt={1}>
                          <Textarea
                            onChange={handleTextareaChange}
                            value={additionalFeedback}
                            maxHeight={"160px"}
                            disabled={!checkedItems.includes("something_else")}
                          />
                        </Flex>
                      )}
                    </Box>
                  ))}
                </CheckboxGroup>
              </Flex>
            </Flex>
          </AlertDialogBody>

          <Divider borderColor={"#000000"} opacity={0.12} />

          <AlertDialogFooter flexDirection={"column"} padding={6}>
            <Text
              fontSize={"16px"}
              lineHeight={"24px"}
              fontWeight={700}
              color={COLORS.black}
            >
              {t("subscription.cancellation.feedback.thanksMessage")}
            </Text>

            <Flex mt={6} gap={5} sx={buttonsStyles}>
              <Button backgroundColor={"#F7F7F9"} onClick={onClose}>
                <Text color={COLORS.red}>
                  {t("subscription.cancellation.cancel.button")}
                </Text>
              </Button>
              <Button
                color="white"
                backgroundColor={COLORS.red}
                isDisabled={isConfirmDisabled}
                onClick={handleConfirm}
              >
                <Text>{t("subscription.cancellation.confirm.button")}</Text>
              </Button>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default FeedbackPopup
