import { Flex, Heading, Text } from "@chakra-ui/react"
import { Trans, useTranslation } from "react-i18next"

const leftFAQsSections = [
  {
    id: 1,
    title: "allReward.faqs.question1",
    description: "allReward.faqs.answer1",
  },
  {
    id: 2,
    title: "allReward.faqs.question2",
    description: "allReward.faqs.answer2",
  },
]

const rightFAQsSections = [
  {
    id: 1,
    title: "allReward.faqs.question3",
    description: "allReward.faqs.answer3",
  },
  {
    id: 2,
    title: "allReward.faqs.question4",
    description: "allReward.faqs.answer4",
  },
]

const FAQsBetweenFlights = ({ flightsTexts }) => {
  const { t } = useTranslation()
  const { from, to } = flightsTexts

  return (
    <Flex
      flexDirection={"column"}
      gap={"30px"}
      padding={"30px"}
      backgroundColor={"white"}
      borderRadius={"12px"}
      textAlign={"left"}
    >
      <Heading as={"h2"} fontSize="24px" textAlign={"left"}>
        {t("allReward.faqs.title", { to: to.name })}
      </Heading>

      <Flex gap={"30px"}>
        <Flex flexDirection={"column"} gap={"24px"}>
          {leftFAQsSections.map(({ id, title, description }) => (
            <Flex key={id} flexDirection={"column"} gap={"8px"}>
              <Heading as={"h4"} fontSize={"16px"}>
                {t(title, { from: from.name, to: to.name })}
              </Heading>
              <Text fontSize={"14px"}>
                <Trans
                  i18nKey={description}
                  values={{ from: from.name, to: to.name }}
                  components={{ bold: <strong /> }}
                />
              </Text>
            </Flex>
          ))}
        </Flex>

        <Flex flexDirection={"column"} gap={"24px"}>
          {rightFAQsSections.map(({ id, title, description }) => (
            <Flex key={id} flexDirection={"column"} gap={"8px"}>
              <Heading as={"h4"} fontSize={"16px"}>
                {t(title, { from: from.name, to: to.name })}
              </Heading>
              <Text fontSize={"14px"}>
                {id === 1 ? (
                  <Trans
                    i18nKey={description}
                    values={{ from: from.name, to: to.name }}
                    components={{ bold: <strong /> }}
                  />
                ) : (
                  <Trans values={{ from: from.name, to: to.name }}>
                    Qantas Classic Rewards provide Qantas Frequent Flyer members
                    the opportunity to use points for flights to Singapore.
                    Available on Qantas, Jetstar, and partner airlines, these
                    rewards offer significant savings on air travel and are
                    fixed based on distance traveled. Classic Rewards are highly
                    sought after for their value and flexibility, allowing
                    members to maximise the utility of their accumulated points.
                    Gyoza is the easiest tool for finding Qantas Reward Flights
                    to <strong>{{ to }}</strong> and booking is straightforward
                    via the Qantas website.
                  </Trans>
                )}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FAQsBetweenFlights
