import { Box, Heading, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { COLORS } from "../constants/constants"

const TermsAndConditions = () => {
  const { t } = useTranslation()

  return (
    <Box
      bg="#F7F7F9"
      px={{ base: 4, lg: 0 }}
      marginInlineStart={0}
      flexGrow={1}
    >
      <Heading
        as="h1"
        pb="2"
        color={COLORS.black}
        fontSize={{ base: "xl", lg: "2xl" }}
      >
        {t("terms.header")}
      </Heading>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.paragraph1")}
      </Text>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.paragraph2")}
      </Text>

      <Heading
        as="h2"
        py="2"
        color={COLORS.black}
        fontSize={{ base: "xl", lg: "2xl" }}
      >
        {t("terms.servicesSubheader")}
      </Heading>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.servicesParagraph1")}
      </Text>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.servicesParagraph2")}
      </Text>

      <Heading
        as="h2"
        py="2"
        color={COLORS.black}
        fontSize={{ base: "xl", lg: "2xl" }}
      >
        {t("terms.useSubheader")}
      </Heading>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.useParagraph1")}
      </Text>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.useParagraph2")}
      </Text>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.useParagraph3")}
      </Text>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.useParagraph4")}
      </Text>

      <Heading
        as="h2"
        py="2"
        color={COLORS.black}
        fontSize={{ base: "xl", lg: "2xl" }}
      >
        {t("terms.disclaimerSubheader")}
      </Heading>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.disclaimerParagraph1")}
      </Text>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.disclaimerParagraph2")}
      </Text>

      <Heading
        as="h2"
        py="2"
        color={COLORS.black}
        fontSize={{ base: "xl", lg: "2xl" }}
      >
        {t("terms.limitationSubheader")}
      </Heading>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.limitationParagraph1")}
      </Text>

      <Heading
        as="h2"
        py="2"
        color={COLORS.black}
        fontSize={{ base: "xl", lg: "2xl" }}
      >
        {t("terms.warrantySubheader")}
      </Heading>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.warrantyParagraph")}
      </Text>

      <Heading
        as="h2"
        py="2"
        color={COLORS.black}
        fontSize={{ base: "xl", lg: "2xl" }}
      >
        {t("terms.indemnificationSubheader")}
      </Heading>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.indemnificationParagraph1")}
      </Text>
      <Text color={COLORS.black} pb="6" fontSize={{ base: "small", lg: "sm" }}>
        {t("terms.indemnificationParagraph2")}
      </Text>
    </Box>
  )
}

export default TermsAndConditions
