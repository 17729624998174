import { useState } from "react"
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Show,
  useToast,
} from "@chakra-ui/react"
import { DeleteIcon, EditIcon, RepeatIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import parse from "date-fns/parse"
import format from "date-fns/format"

import { getAlerts, deleteAlert, updateAlert } from "../services/api"
import AlertRouteContent from "../components/AlertRouteContent"
import { trackPage } from "../services/analytics"
import { COLORS } from "../constants/constants"
import Footer from "../components/Footer"

const DATE_FORMAT = "MMMM dd, yyyy"
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd"

const parseDate = (dateStr) => {
  const parsedDate = parse(dateStr, DEFAULT_DATE_FORMAT, new Date())
  return format(parsedDate, DATE_FORMAT)
}

const flightClassesMapping = {
  Economy: "economy",
  PremiumEconomy: "premiumEconomy",
  Business: "business",
  First: "first",
}

const Alerts = () => {
  const [openAlerts, setOpenAlerts] = useState({})
  const toast = useToast()
  const queryClient = useQueryClient()
  const { data: alerts } = useQuery({
    queryKey: ["alerts"],
    queryFn: getAlerts,
    initialData: [],
  })

  const onOpen = (alertId) => {
    setOpenAlerts((prev) => ({ ...prev, [alertId]: true }))
  }

  const onClose = (alertId) => {
    setOpenAlerts((prev) => ({ ...prev, [alertId]: false }))
  }

  const { mutateAsync: updateAlertMutation } = useMutation({
    mutationFn: updateAlert,
    onSuccess: () => queryClient.invalidateQueries(["alerts"]),
  })

  const { mutateAsync: deleteAlertMutation } = useMutation({
    mutationFn: deleteAlert,
    onSuccess: () => queryClient.invalidateQueries(["alerts"]),
  })
  const { t } = useTranslation()

  const onAlertDelete = async (userAlert) => {
    await deleteAlertMutation(userAlert.id)
    onClose()
  }

  const handleRepeatAlert = async (route) => {
    const formattedData = {
      start_date: route.startDate,
      end_date: route.endDate,
      origin: route.origin.id,
      destination: route.destination.id,
      flight_classes: route.flightClasses,
      preferred_programs: route.preferredPrograms,
      direct: route.isDirect,
      status: "Pending",
    }

    try {
      await updateAlertMutation({ id: route.id, ...formattedData })

      toast({
        position: "bottom-right",
        title: t("alertRouteModal.toastSuccess"),
        status: "success",
      })
    } catch (err) {
      toast({
        position: "bottom-right",
        title: t("alertRouteModal.editToastError"),
        status: "error",
      })
    }
  }

  return (
    <Flex
      bg="#F7F7F9"
      flexGrow={1}
      minHeight={{ base: "calc(100vh-48px)", lg: "calc(100vh-60px)" }}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box
        bg="#F7F7F9"
        px={{ base: 4, lg: 0 }}
        marginInlineStart={0}
        flexGrow={1}
      >
        <Heading
          as="h1"
          pb={6}
          color={COLORS.black}
          textAlign="left"
          fontSize={{ base: "xl", lg: "2xl" }}
        >
          {t("alerts.header")}
        </Heading>
        <Box bg="white" borderRadius={12} mb={7}>
          {alerts.length > 0 ? (
            <Table
              width={"100%"}
              sx={{ tableLayout: "fixed" }}
              textAlign={"left"}
            >
              <Thead>
                <Tr
                  boxShadow="0px 2px 8px rgba(20, 23, 37, 0.08)"
                  borderTopRadius="12px"
                  sx={{
                    "& > th": {
                      fontSize: { base: "2xs", sm: "sm" },
                    },
                  }}
                >
                  <Th textTransform="none" p={4} w={{ base: "18%", sm: "20%" }}>
                    {t("alerts.itinerary")}
                  </Th>
                  <Th textTransform="none" p={4} w={{ base: "22%", sm: "20%" }}>
                    {t("alerts.dateRange")}
                  </Th>
                  <Th textTransform="none" p={4} w={{ base: "15%", sm: "18%" }}>
                    {t("alerts.class")}
                  </Th>
                  <Show above={"lg"}>
                    <Th textTransform="none" p={4} w={"18%"}>
                      {t("alerts.program")}
                    </Th>
                  </Show>
                  <Th textTransform="none" p={4} w={"14%"}>
                    {t("alerts.status")}
                  </Th>
                  <Th
                    textTransform="none"
                    p={2}
                    w={{ base: "12%", md: "10%" }}
                  ></Th>
                </Tr>
              </Thead>
              <Tbody>
                {alerts.map((userAlert) => {
                  const flightClasses = userAlert.flight_classes.map(
                    (flightClass) =>
                      t(`table.${flightClassesMapping[flightClass]}`)
                  )

                  const route = {
                    id: userAlert.id,
                    origin: userAlert.origin,
                    destination: userAlert.destination,
                    startDate: userAlert.start_date,
                    endDate: userAlert.end_date,
                    flightClasses: userAlert.flight_classes,
                    preferredPrograms: userAlert.preferred_programs,
                    isDirect: userAlert.direct,
                    status: userAlert.status,
                  }

                  return (
                    <Tr
                      key={userAlert.id}
                      sx={{
                        "& > td": {
                          fontSize: { base: "2xs", sm: "xs", md: "sm" },
                        },
                      }}
                    >
                      <Td p={4} textAlign={"left"}>
                        {userAlert.origin.name} - {userAlert.destination.name}
                      </Td>
                      <Td p={4}>
                        {parseDate(userAlert.start_date)} -{" "}
                        {parseDate(userAlert.end_date)}
                      </Td>
                      <Td p={4}>{flightClasses.join(", ")}</Td>
                      <Show above={"lg"}>
                        <Td p={4}>
                          {userAlert.preferred_programs
                            .join(", ")
                            .toUpperCase()}
                        </Td>
                      </Show>
                      <Td p={4}>
                        <Flex alignItems={"center"} gap={2}>
                          <Text>
                            {userAlert.status === "Pending"
                              ? "Active"
                              : userAlert.status}
                          </Text>
                          {userAlert.status === "Sent" && (
                            <Button
                              onClick={() => handleRepeatAlert(route)}
                              padding={0}
                              width={"30px"}
                              height={"30px"}
                              minWidth={"30px"}
                            >
                              <RepeatIcon color={"red"} />
                            </Button>
                          )}
                        </Flex>
                      </Td>
                      <Td p={2} textAlign="center">
                        <Flex gap={{ base: 1, sm: 2, md: 4 }}>
                          <Flex>
                            <DeleteIcon
                              cursor="pointer"
                              boxSize={{ base: 4, md: 5 }}
                              color="#6a6e85"
                              onClick={() => onOpen(userAlert.id)}
                            />
                            <Modal
                              isOpen={openAlerts[userAlert.id]}
                              onClose={() => onClose(userAlert.id)}
                              isCentered
                            >
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader fontSize={"2xl"} fontWeight="bold">
                                  {t("alerts.deleteHeader")}
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                  {t("alerts.deleteContent")}
                                </ModalBody>

                                <ModalFooter>
                                  <Flex width="100%" gap={5}>
                                    <Button
                                      flex={1}
                                      textTransform={"uppercase"}
                                      backgroundColor="#F7F7F9"
                                      color="#DD0000"
                                      onClick={() => {
                                        onClose(userAlert.id)
                                      }}
                                    >
                                      {t("alerts.deleteCancel")}
                                    </Button>
                                    <Button
                                      flex={1}
                                      textTransform={"uppercase"}
                                      backgroundColor="#DD0000"
                                      color="white"
                                      onClick={() => onAlertDelete(userAlert)}
                                    >
                                      {t("alerts.deleteAgree")}
                                    </Button>
                                  </Flex>
                                </ModalFooter>
                              </ModalContent>
                            </Modal>
                          </Flex>

                          <Flex>
                            <Popover
                              placement={"left"}
                              closeOnBlur={true}
                              onOpen={() => {
                                trackPage({
                                  title: "Alert Route",
                                })
                              }}
                            >
                              {({ onClose }) => (
                                <>
                                  <PopoverTrigger>
                                    <EditIcon
                                      cursor="pointer"
                                      boxSize={{ base: 4, md: 5 }}
                                      color="#6a6e85"
                                    />
                                  </PopoverTrigger>
                                  <PopoverContent
                                    p={5}
                                    _focus={{ boxShadow: "none" }}
                                    boxShadow="0px 10px 22px rgba(0, 0, 0, 0.14);"
                                    borderRadius={8}
                                    w={{ base: "100%", sm: 380 }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <PopoverCloseButton />
                                    <PopoverBody p={0}>
                                      <AlertRouteContent
                                        route={route}
                                        onClose={onClose}
                                        isNew={false}
                                      />
                                    </PopoverBody>
                                  </PopoverContent>
                                </>
                              )}
                            </Popover>
                          </Flex>
                        </Flex>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          ) : (
            <Text p={2}>{t("alerts.empty")}</Text>
          )}
        </Box>
      </Box>

      <Footer />
    </Flex>
  )
}

export default Alerts
