import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Button, Flex, Text } from "@chakra-ui/react"
import {
  DepartAirlaneSVG,
  ReturnAirlaneSVG,
} from "../../../helpers/svgComponent.js"
import { Select } from "../../Select.js"
import {
  COLORS,
  PROGRAMS_OPTIONS,
  STOPS_OPTIONS,
} from "../../../constants/constants"

const EnhancedFiltration = ({
  from,
  to,
  is_reversed,
  isOneWay,
  onChange,
  program,
  stops,
  isSuccessFlights,
  isFreePlan,
}) => {
  const { t } = useTranslation()

  const [showProgramStops, setShowProgramStops] = useState(
    !isFreePlan && isSuccessFlights
  )

  const [showSubmitButton, setShowSubmitButton] = useState(isSuccessFlights)

  const { handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      is_reversed: is_reversed,
      program: program,
      stops: stops,
    },
  })

  useEffect(() => {
    if (!showProgramStops && !isFreePlan && isSuccessFlights) {
      setShowProgramStops(true)
    }
  }, [isFreePlan, isSuccessFlights, showProgramStops])

  useEffect(() => {
    if (!showSubmitButton && isSuccessFlights) {
      setShowSubmitButton(true)
    }
  }, [isSuccessFlights, showSubmitButton])

  useEffect(() => {
    if (isOneWay) {
      setValue("is_reversed", false)
    }
  }, [isOneWay, setValue])

  const onSubmit = (data) => {
    setValue("is_reversed", data.is_reversed)
    setValue("program", data.program)
    setValue("stops", data.stops)

    onChange({
      is_reversed: data.is_reversed,
      program: data.program,
      stops: data.stops,
    })
  }

  // Watch form values
  const watchIsReversed = watch("is_reversed", is_reversed)
  const watchProgram = watch("program", program)
  const watchStops = watch("stops", stops)

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "flex-start", md: "center" }}
          gap={"6px"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          {showSubmitButton && (
            <Flex
              bg={"#F7F7F9"}
              borderRadius={12}
              width={"100%"}
              maxWidth={{ base: "100%", md: "338px" }}
            >
              <Button
                bg={watchIsReversed ? "#F7F7F9" : "#ffffff"}
                color={watchIsReversed ? "#6A6E85" : COLORS.red}
                px={"12px"}
                mr={"2px"}
                height={8}
                borderRadius={8}
                isDisabled={isOneWay}
                onClick={() => setValue("is_reversed", false)}
                width={"100%"}
                maxWidth={{ base: "100%", md: "168px" }}
              >
                <DepartAirlaneSVG
                  fill={watchIsReversed ? "#6A6E85" : COLORS.red}
                />
                <Text
                  fontSize={"14px"}
                  ml={{ base: "4px", md: "12px" }}
                  fontWeight={700}
                >
                  Depart:{" "}
                  {from?.value && to?.value ? `${from.value}-${to.value}` : ""}
                </Text>
              </Button>

              <Button
                bg={watchIsReversed ? "#ffffff" : "#F7F7F9"}
                color={watchIsReversed ? COLORS.red : "#6A6E85"}
                height={8}
                borderRadius={8}
                onClick={() => setValue("is_reversed", true)}
                isDisabled={isOneWay || !to}
                px={"12px"}
                width={"100%"}
                maxWidth={{ base: "100%", md: "168px" }}
              >
                <ReturnAirlaneSVG
                  fill={watchIsReversed ? COLORS.red : "#6A6E85"}
                />
                <Text fontSize={"14px"} ml={"12px"} fontWeight={700}>
                  Return:{" "}
                  {from?.value && to?.value ? `${to.value}-${from.value}` : ""}
                </Text>
              </Button>
            </Flex>
          )}

          <Flex
            gap={4}
            justifyContent={"flex-end"}
            width={"100%"}
            flexDirection={{ base: "column", md: "row" }}
          >
            {showProgramStops && (
              <Flex
                gap={4}
                width={"100%"}
                justifyContent={"flex-end"}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box
                  bg={"#FFFFFF"}
                  borderRadius={"12px"}
                  width={"100%"}
                  maxWidth={{ base: "100%", md: "275px" }}
                  minWidth={"150px"}
                >
                  <Select
                    placeholder={PROGRAMS_OPTIONS[0].label}
                    labelLeftText={"Program:"}
                    onChange={(program) => setValue("program", program.value)}
                    value={PROGRAMS_OPTIONS.find(
                      (option) => option.value === watchProgram?.value
                    )}
                    options={PROGRAMS_OPTIONS}
                    singleValueStyles={{ fontSize: "14px" }}
                  />
                </Box>

                <Box
                  bg={"#FFFFFF"}
                  borderRadius={"12px"}
                  width={"100%"}
                  maxWidth={{ base: "100%", md: "145px" }}
                  minWidth={"120px"}
                >
                  <Select
                    placeholder={STOPS_OPTIONS[0].label}
                    labelLeftText={"Stops:"}
                    onChange={(stop) => setValue("stops", stop.value)}
                    value={STOPS_OPTIONS.find(
                      (option) => option.value === watchStops?.value
                    )}
                    options={STOPS_OPTIONS}
                    singleValueStyles={{ fontSize: "14px" }}
                  />
                </Box>
              </Flex>
            )}

            {showSubmitButton && (
              <Button
                type={"submit"}
                bg={COLORS.red}
                color={"#ffffff"}
                width={"100%"}
                maxWidth={{ base: "100%", md: "275px" }}
              >
                {t("allReward.filterSubmitButton")}
              </Button>
            )}
          </Flex>
        </Flex>
      </form>
    </Box>
  )
}

export default EnhancedFiltration
