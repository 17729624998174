import { Fragment } from "react"
import { format, parseISO, formatDistanceToNow } from "date-fns"
import { Text, Image, Flex, Box } from "@chakra-ui/react"

import {
  COLORS,
  DATE_FORMAT_EXPANDABLE_ROW,
} from "../../../constants/constants"

import flightImages from "../../../flightImages"
import airplane from "../../../img/airplane.svg"

const parseDate = (dateStr) => {
  return parseISO(dateStr)
}

const ExpandableRow = ({ flight }) => {
  const showFlightClasses = (flightClass, index) => {
    const uniqueFlightClasses = flightClass.map(({ cabin_type }) => {
      const classArr = cabin_type.split(", ")
      return classArr[index]
    })

    const sortOrder = ["Economy", "PremiumEconomy", "Business", "First"]

    uniqueFlightClasses.sort((a, b) => {
      const indexA = sortOrder.indexOf(a)
      const indexB = sortOrder.indexOf(b)
      if (indexA === -1) return 1 // If not found, place at the end
      if (indexB === -1) return -1 // If not found, place at the end
      return indexA - indexB
    })

    return uniqueFlightClasses.join(", ")
  }

  const adjustTimezone = (dateStr, timezoneOffsetInHoursFromUTC) => {
    const [datePart, timePart] = dateStr.split("T")
    const [year, month, day] = datePart.split("-").map(Number)
    const [hour, minute, second] = timePart.split(":").map(Number)

    const utcDate = new Date(
      Date.UTC(
        year,
        month - 1,
        day,
        hour - timezoneOffsetInHoursFromUTC,
        minute,
        second
      )
    )

    return utcDate.toISOString()
  }

  return (
    <Box>
      {flight.details.map((detail, index, details) => {
        let airlineImageAlt = ""

        if (detail.aircraft_details.startsWith("EK")) {
          airlineImageAlt = `book_emirates_reward_flights_to_${detail.to_airport}`
        } else if (detail.aircraft_details.startsWith("UA")) {
          airlineImageAlt = `book_united_airlines_reward_flights_to_${detail.to_airport}`
        } else {
          airlineImageAlt = `book_${detail.from_airport}_reward_flights_to_${detail.to_airport}`
        }

        const flightTimeInUTC = adjustTimezone(flight.timestamp, 10)

        const lastSeen = formatDistanceToNow(parseDate(flightTimeInUTC), {
          addSuffix: true,
        })

        const lastSeenText =
          lastSeen === "less than a minute ago"
            ? "around 1 minute ago"
            : lastSeen

        const shouldIncludeAbout =
          !lastSeenText.includes("about") &&
          lastSeenText !== "around 1 minute ago"

        const planeImage =
          details.length >= 3
            ? flightImages("group_3_plus")
            : flightImages(detail.aircraft_details.slice(0, 2))

        return (
          <Fragment key={index}>
            <Flex my={6} fontSize="sm" fontWeight="semibold">
              <Flex alignItems="center" gap="10px" w="36px">
                <Image
                  width="100%"
                  src={planeImage}
                  margin="0 auto"
                  zIndex={1}
                  alt={airlineImageAlt}
                />
                {/* <Box
                  height={84}
                  position="relative"
                  borderLeft={"1px solid #B6BAD1"}
                  mr={6}
                  _before={{
                    content: '""',
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    border: "1px solid #B6BAD1",
                    position: "absolute",
                    top: "-13px",
                    left: "-7px",
                  }}
                  _after={{
                    content: '""',
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    border: "1px solid #B6BAD1",
                    position: "absolute",
                    bottom: "-13px",
                    left: "-7px",
                  }}
                /> */}
              </Flex>

              <Image
                src={airplane}
                width="24px"
                height="108px"
                alt={`reward_flight_duration_between_${detail.from_airport}_and_${detail.to_airport}`}
                alignSelf="center"
                mr="2px"
              />

              <Box w={"45%"} fontSize="12px">
                <Text color={COLORS.secondary}>
                  {format(
                    parseDate(detail.departure_date),
                    DATE_FORMAT_EXPANDABLE_ROW
                  )}
                </Text>
                <Text>{detail.from_airport}</Text>
                <Text color={COLORS.secondary} my={5} fontSize={"xs"}>
                  {detail.flight_duration}
                </Text>
                <Text color={COLORS.secondary}>
                  {format(
                    parseDate(detail.arrival_date),
                    DATE_FORMAT_EXPANDABLE_ROW
                  )}
                </Text>
                <Text>{detail.to_airport}</Text>
              </Box>

              <Box w={"45%"} fontSize={"12px"}>
                <Text color={COLORS.secondary}>
                  Flight: {detail.aircraft_details}
                </Text>
                <Text color={COLORS.secondary}>
                  Availability: {showFlightClasses(flight.class_details, index)}
                </Text>
                {detail?.equipment && (
                  <Text color={COLORS.secondary}>
                    Aircraft: {detail.equipment}
                  </Text>
                )}
                <Text color={COLORS.secondary}>
                  Last seen: {shouldIncludeAbout ? "about " : ""}
                  {lastSeenText}
                </Text>
              </Box>
            </Flex>
            {index < details.length - 1 && (
              <Box
                ml="60px"
                py={2}
                borderTop="1px solid #D4D4D9;"
                borderBottom="1px solid #D4D4D9;"
                fontWeight="semibold"
                fontSize={"12px"}
              >
                <Text>
                  Layover: {detail.to_airport} {detail.transition_time}
                </Text>
              </Box>
            )}
          </Fragment>
        )
      })}
    </Box>
  )
}

export default ExpandableRow
