import range from "lodash/range"

import QantasBookContent from "../components/QantasBookContent"
import VelocityBookContent from "../components/VelocityBookContent"
import AmericanBookContent from "../components/AmericanBookContent"
import UnitedBookContent from "../components/UnitedBookContent"
import AviosBookContent from "../components/AviosBookContent"
import AeroplanBookContent from "../components/AeroplanBookContent"
// import AlaskaBookContent from "../components/AlaskaBookContent"
// import DeltaSkymilesBookContent from "../components/DeltaSkymilesBookContent"
// import JetBlueBookContent from "../components/JetBlueBookContent"

import QFAwards from "../img/qantas_icon.svg"
import VAAwards from "../img/VA.svg"
import AAAwards from "../img/AA.svg"
import UAAwards from "../img/UA.svg"
import AVAwards from "../img/AV.svg"
import APAwards from "../img/AP.svg"
import { ANY } from "../constants/constants"
// import ALAwards from "../img/alaska.png"
// import DSAwards from "../img/delta_skymiles.png"
// import JBAwards from "../img/jet_blue.png"

export const getPagesToRender = (currentPage, pageCount) => {
  if (pageCount <= 6) {
    return range(1, pageCount + 1)
  }
  switch (currentPage) {
    case 1:
    case 2:
      return [1, 2, 3, "...", pageCount]
    case 3:
      return [1, 2, 3, 4, "...", pageCount]
    case 4:
      return [1, 2, 3, 4, 5, "...", pageCount]
    case pageCount - 4:
      return [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        pageCount,
      ]
    case pageCount - 3:
      return [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        pageCount,
      ]
    case pageCount - 2:
      return [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        pageCount,
      ]
    case pageCount - 1:
      return [1, "...", currentPage - 1, currentPage, pageCount]
    case pageCount:
      return [1, "...", currentPage - 1, currentPage]
    default:
      return [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        pageCount,
      ]
  }
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const handleFlightSelection = ({ from, to }) => {
  if (from && to) {
    const fromLabelCleaned = from.fullLabel
      .replace(/\([^)]*\)/g, "")
      .toLowerCase()
    const toLabelCleaned = to.fullLabel.replace(/\([^)]*\)/g, "").toLowerCase()

    const fromWordsArr = fromLabelCleaned
      .split(/\s+|[-/'’]+/)
      .filter((word) => /^[a-zA-Z]+$/.test(word))
    const toWordsArr = toLabelCleaned
      .split(/\s+|[-/'’]+/)
      .filter((word) => /^[a-zA-Z]+$/.test(word))

    let fromValidation = fromWordsArr.filter((word) => {
      const isAlphabetic = /^[a-zA-Z]+$/.test(word)
      return isAlphabetic && !["", " ", "-"].includes(word)
    })
    let toValidation = toWordsArr.filter((word) => {
      const isAlphabetic = /^[a-zA-Z]+$/.test(word)
      return isAlphabetic && !["", " ", "-"].includes(word)
    })

    const fromURL = fromValidation.join("-") + "-" + from.value
    const toURL = toValidation.join("-") + "-" + to.value

    return { fromURL, toURL }
  }
}

export const getCodeFromRoute = (route) => {
  const routeParts = route.split("-to-")
  if (routeParts.length === 2) {
    const [originRoute, destinationRoute] = routeParts

    const origin = originRoute.slice(-3).toLowerCase()
    const destination = destinationRoute.slice(-3).toLowerCase()

    const origin_city =
      originRoute.slice(-3).toLowerCase() === ANY.toLowerCase()
        ? originRoute.substring(0, originRoute.lastIndexOf("-"))
        : ""
    const destination_city =
      destinationRoute.slice(-3).toLowerCase() === ANY.toLowerCase()
        ? destinationRoute.substring(0, destinationRoute.lastIndexOf("-"))
        : ""

    return { origin, destination, origin_city, destination_city }
  }

  return null
}

export const generateRandomNumbers = (range) => {
  const numbers = []
  while (numbers.length < range) {
    const num = Math.floor(Math.random() * range)
    if (numbers.indexOf(num) === -1) numbers.push(num)
  }
  return numbers
}

export const renderBookingDetails = (flight, highestPoint, t) => {
  const detailsMap = {
    QF: {
      airlineAwardImage: QFAwards,
      bookingContentComponent: (
        <QantasBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "qantas",
        to: flight.destination.name,
      }),
      preferredPrograms: "qf",
    },
    VA: {
      airlineAwardImage: VAAwards,
      bookingContentComponent: (
        <VelocityBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "velocity",
        to: flight.destination.name,
      }),
      preferredPrograms: "va",
    },
    AA: {
      airlineAwardImage: AAAwards,
      bookingContentComponent: (
        <AmericanBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "american",
        to: flight.destination.name,
      }),
      preferredPrograms: "aa",
    },
    UA: {
      airlineAwardImage: UAAwards,
      bookingContentComponent: (
        <UnitedBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "united",
        to: flight.destination.name,
      }),
      preferredPrograms: "ua",
    },
    AV: {
      airlineAwardImage: AVAwards,
      bookingContentComponent: (
        <AviosBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "avios",
        to: flight.destination.name,
      }),
      preferredPrograms: "av",
    },
    AP: {
      airlineAwardImage: APAwards,
      bookingContentComponent: (
        <AeroplanBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "aeroplan",
        to: flight.destination.name,
      }),
      preferredPrograms: "ap",
    },
    // AL: {
    //   airlineAwardImage: ALAwards,
    //   bookingContentComponent: (
    //     <AlaskaBookContent points={highestPoint.points} />
    //   ),
    //   airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
    //     program: "alaska",
    //     to: flight.destination.name,
    //   }),
    //   preferredPrograms: "al",
    // },
    // DS: {
    //   airlineAwardImage: DSAwards,
    //   bookingContentComponent: (
    //     <DeltaSkymilesBookContent points={highestPoint.points} />
    //   ),
    //   airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
    //     program: "delta_skymiles",
    //     to: flight.destination.name,
    //   }),
    //   preferredPrograms: "ds",
    // },
    // JB: {
    //   airlineAwardImage: JBAwards,
    //   bookingContentComponent: (
    //     <JetBlueBookContent points={highestPoint.points} />
    //   ),
    //   airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
    //     program: "jet_blue",
    //     to: flight.destination.name,
    //   }),
    //   preferredPrograms: "jb",
    // },
    default: {
      airlineAwardImage: QFAwards,
      bookingContentComponent: (
        <QantasBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "qantas",
        to: flight.destination.name,
      }),
      preferredPrograms: "Qantas FF",
    },
  }

  return detailsMap[flight.source] || detailsMap.default
}
