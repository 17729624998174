import React from "react"
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react"
import { format, parseISO } from "date-fns"

import {
  COLORS,
  DATE_FORMAT_LIKELIHOOD_POPOVER,
} from "../../../../constants/constants"

import likelihood_airplane from "../../../../img/likelihood_airplane.svg"

const parseDate = (dateStr) => {
  return parseISO(dateStr)
}

const PopoverTop = ({
  percentage,
  details,
  planeImage,
  planeAltText,
  aircraft,
  flightDetail,
  popoverFlightsTexts,
}) => {
  return (
    <Flex
      width={"100%"}
      padding={"60px"}
      justifyContent={"space-between"}
      backgroundColor={"#F7F7F9"}
      gap={10}
    >
      <Flex flex={"1 1 auto"} gap={"42px"}>
        <Flex flexDirection={"column"} gap={4}>
          <CircularProgress
            size="126px"
            value={percentage || 0}
            color="#DD0000"
            width={"fit-content"}
          >
            <CircularProgressLabel>
              <Text fontSize={"26px"} color="#DD0000" fontWeight={900}>{`${
                percentage || 0
              }%`}</Text>
              <Text fontSize={"12px"} fontWeight={600} color={COLORS.black}>
                capacity
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
        </Flex>
        <Flex flexDirection={"column"} gap={"24px"}>
          <Text fontSize="24px" fontWeight={"700"}>
            {aircraft.name}{" "}
            <Text as={"span"} color={COLORS.red}>
              {aircraft.code}
            </Text>
          </Text>
          <Flex>
            <Image
              src={likelihood_airplane}
              width="32px"
              height="125px"
              alt={`reward_flight_duration_between_${flightDetail.from_airport}_and_${flightDetail.to_airport}`}
              alignSelf="center"
              mr={5}
            />

            <Box>
              <Text
                fontSize={"16px"}
                fontWeight={600}
                color={COLORS.black}
                marginBottom={1}
              >
                {format(
                  parseDate(flightDetail.departure_date),
                  DATE_FORMAT_LIKELIHOOD_POPOVER
                )}
              </Text>
              <Text fontSize={"20px"} fontWeight={700}>
                {popoverFlightsTexts[0].from.fullName}
              </Text>
              <Text color={COLORS.secondary} my={5} fontSize={"xs"}>
                {flightDetail.flight_duration}
              </Text>
              <Text
                fontSize={"16px"}
                fontWeight={600}
                color={COLORS.black}
                marginBottom={1}
              >
                {format(
                  parseDate(flightDetail.arrival_date),
                  DATE_FORMAT_LIKELIHOOD_POPOVER
                )}
              </Text>
              <Text fontSize={"20px"} fontWeight={700}>
                {popoverFlightsTexts[0].to.fullName}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Box
        borderRadius={"18px"}
        backgroundColor={"#fff"}
        padding={"21px 12px 21px 17px"}
        height={"fit-content"}
        boxShadow="0px 5.25px 21px 0px rgba(0, 0, 0, 0.1)"
      >
        <Image
          src={planeImage[0].image}
          alt={planeImage[0].alt}
          width={"55px"}
        />
      </Box>
    </Flex>
  )
}

export default PopoverTop
