import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"

const businessClassData = [
  {
    id: 1,
    firstColumn: "J",
    secondColumn:
      "Full priced, fully flexible Business Class tickets e.g. fully refundable",
  },
  {
    id: 2,
    firstColumn: "C",
    secondColumn:
      "full-fare Business Class, but sometimes with fewer restrictions than J",
  },
  {
    id: 3,
    firstColumn: "D",
    secondColumn:
      "discounted Business Class fare that might have some restrictions eg cancellation fees",
  },
  {
    id: 4,
    firstColumn: "I",
    secondColumn:
      "deeply discounted or promotional Business Class fares. No refund",
  },
  {
    id: 5,
    firstColumn: "Z, U",
    secondColumn: "Business Class Reward fares",
  },
]

const firstClassData = [
  {
    id: 1,
    firstColumn: "F",
    secondColumn:
      "Full priced, fully flexible First Class tickets e.g. fully refundable",
  },
  {
    id: 2,
    firstColumn: "A",
    secondColumn: "Full-fare First Class, but with some restrictions",
  },
  {
    id: 3,
    firstColumn: "O",
    secondColumn: "First Class Reward fare",
  },
]

const AirlineCodesInfo = () => {
  const { t } = useTranslation()

  const FirstColumn = ({ children, ...css }) => (
    <Box flex={"1 1 170px"} {...css}>
      {children}
    </Box>
  )

  const SecondColumn = ({ children, ...css }) => (
    <Box flex={"4 4 874px"} {...css}>
      {children}
    </Box>
  )

  return (
    <Flex
      flexDirection={"column"}
      gap={"30px"}
      padding={"30px"}
      backgroundColor={"white"}
      borderRadius={"12px"}
      textAlign={"left"}
    >
      <Flex flexDirection={"column"} rowGap={2}>
        <Heading as={"h2"} fontSize="24px" textAlign={"left"}>
          {t("amadeus.airlineCodesInfo.title")}
        </Heading>
        <Text fontSize={"14px"} lineHeight={"18px"}>
          {t("amadeus.airlineCodesInfo.descriptionPart1")}
          <br />
          <br />
          {t("amadeus.airlineCodesInfo.descriptionPart2")}
          <br />
          <br />
          {t("amadeus.airlineCodesInfo.descriptionPart3")}
        </Text>
      </Flex>

      <Flex flexDirection={"column"} gap={"30px"}>
        <Flex flexDirection={"column"} gap={"12px"}>
          <Heading as={"h4"} fontSize={"16px"} lineHeight={"20px"}>
            {t("amadeus.airlineCodesInfo.businessClass")}
          </Heading>
          <Flex
            flexDirection={"column"}
            border={"1px solid #EFEFEF"}
            borderRadius={"12px"}
            overflow={"hidden"}
          >
            <Flex
              padding={4}
              fontWeight={700}
              fontSize={"12px"}
              lineHeight={"14px"}
              backgroundColor={"#F7F7F9"}
            >
              <FirstColumn>
                <Text>Fare code</Text>
              </FirstColumn>

              <SecondColumn>
                <Text>Description</Text>
              </SecondColumn>
            </Flex>

            {businessClassData.map(({ id, firstColumn, secondColumn }) => (
              <Flex key={id} padding={4} fontSize={14} fontWeight={600}>
                <FirstColumn>{firstColumn}</FirstColumn>
                <SecondColumn>{secondColumn}</SecondColumn>
              </Flex>
            ))}
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"12px"}>
          <Heading as={"h4"} fontSize={"16px"} lineHeight={"20px"}>
            {t("amadeus.airlineCodesInfo.firstClass")}
          </Heading>
          <Flex
            flexDirection={"column"}
            border={"1px solid #EFEFEF"}
            borderRadius={"12px"}
            overflow={"hidden"}
          >
            <Flex
              padding={4}
              fontSize={"12px"}
              fontWeight={700}
              backgroundColor={"#F7F7F9"}
            >
              <FirstColumn>
                <Text>Fare code</Text>
              </FirstColumn>

              <SecondColumn>
                <Text>Description</Text>
              </SecondColumn>
            </Flex>

            {firstClassData.map(({ id, firstColumn, secondColumn }) => (
              <Flex key={id} padding={4} fontSize={14} fontWeight={600}>
                <FirstColumn>{firstColumn}</FirstColumn>
                <SecondColumn>{secondColumn}</SecondColumn>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AirlineCodesInfo
