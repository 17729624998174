import { Flex } from "@chakra-ui/react"
import Footer from "../../Footer"

const AllAvailabilityLayout = ({ children, isLoading }) => {
  return (
    <Flex
      position={"relative"}
      minHeight={{ base: "calc(100vh-48px)", lg: "calc(100vh-60px)" }}
      flexGrow={1}
      flexDirection={"column"}
      justifyContent={"space-between"}
      bg="#F7F7F9"
    >
      <Flex w={"100%"} mb={"46px"} flexDirection={"column"}>
        {children}
      </Flex>
      <Footer />
    </Flex>
  )
}

export default AllAvailabilityLayout
