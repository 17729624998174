import { useTranslation } from "react-i18next"
import { Button, Flex, Image, Box, Text } from "@chakra-ui/react"
import aeroplanImage from "../img/aeroplan.png"

const numberFormat = new Intl.NumberFormat()

const AeroplanBookContent = ({ points }) => {
  const { t } = useTranslation()

  const howToBookUnitedLink = t("links.howToBookUnitedLink")

  return (
    <Flex flexDirection={"column"} gap={5}>
      <Flex justify={"space-between"} gap={"42px"} alignItems={"center"}>
        <Box>
          <Image src={aeroplanImage} height={"24px"} alt={"aeroplan_logo"} />
        </Box>
        <Box>
          <Text fontSize={"md"} fontWeight={"bold"} whiteSpace={"nowrap"}>
            {numberFormat.format(points)} {t("table.bookPoints")}
          </Text>
          <Text align={"right"} fontWeight={"bold"}>
            {t("table.bookTaxes")}
          </Text>
        </Box>
      </Flex>
      <Button
        as="a"
        href={howToBookUnitedLink}
        target="_blank"
        w={"100%"}
        height={"36px"}
        backgroundColor="#DD0000"
        color="white"
        borderRadius={8}
        boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.24)"}
      >
        {t("table.bookButton")}
      </Button>
    </Flex>
  )
}

export default AeroplanBookContent
