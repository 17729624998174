import { Box, Heading, Text } from "@chakra-ui/react"
import { COLORS } from "../../../constants/constants"
import { useTranslation } from "react-i18next"

const Head = ({ isFetching, isRequested, flightsTexts }) => {
  const { t } = useTranslation()

  return (
    <Box px={{ base: 4, lg: 0 }}>
      <Heading
        as="h1"
        align="left"
        pb="2"
        color={COLORS.black}
        fontSize={{ base: "xl", lg: "2xl" }}
        lineHeight={"30px"}
        minHeight={"38px"}
      >
        {!isFetching
          ? isRequested && flightsTexts
            ? t("allReward.title", {
                from: flightsTexts.from.fullName,
                to: flightsTexts.to.fullName,
              })
            : t("allReward.titleDefault")
          : ""}
      </Heading>
      <Text
        align="left"
        color={COLORS.black}
        pb={isRequested ? "5" : "10"}
        fontSize={{ base: "small", lg: "sm" }}
      >
        {!isFetching && isRequested && flightsTexts
          ? t("allReward.description", {
              from: flightsTexts.from.name,
              to: flightsTexts.to.name,
            })
          : t("allReward.defaultDescription")}
      </Text>
    </Box>
  )
}

export default Head
