import React from "react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { COLORS } from "../constants/constants"

const buttonsStyles = {
  button: {
    width: "122px",
  },
  p: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "uppercase",
  },
}

const SubscriptionPopup = ({
  isOpen,
  onClose,
  onConfirm,
  header,
  body,
  type,
  plan,
}) => {
  const { t } = useTranslation()

  const handleConfirmation = () => {
    if (type === "cancel") {
      onConfirm()
    }
    if (type === "switch") {
      onConfirm(plan)
    }
  }

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent maxWidth="lg">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {header}
          </AlertDialogHeader>
          <AlertDialogBody>{body}</AlertDialogBody>
          <AlertDialogFooter justifyContent="center" sx={buttonsStyles}>
            <Flex gap={5}>
              <Button backgroundColor={"#F7F7F9"} onClick={onClose}>
                <Text color={COLORS.red}>
                  {t("subscription.cancellation.cancel.button")}
                </Text>
              </Button>
              <Button
                color="white"
                backgroundColor={COLORS.red}
                onClick={handleConfirmation}
              >
                <Text>{t("subscription.cancellation.confirm.button")}</Text>
              </Button>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default SubscriptionPopup
