import { Flex, Heading, Image, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import arrowRight from "../img/arrow-right.svg"
import { useNavigate } from "react-router-dom"
import { handleFlightSelection } from "../helpers/functions"

const PopularFlights = ({
  flightsTexts,
  top10Airports,
  url,
  isDefault = false,
}) => {
  const { t } = useTranslation()
  const { to } = flightsTexts
  const navigate = useNavigate()

  const handleAirportClick = (from, to) => {
    const { fromURL, toURL } = handleFlightSelection({
      from: { value: from.code, fullLabel: from.name },
      to: { value: to.code, fullLabel: to.name },
    })

    navigate(`/${url}/${fromURL}-to-${toURL}`)

    window.scrollTo(0, 0)
  }

  return (
    <Flex
      flexDirection={"column"}
      gap={"20px"}
      padding={"30px"}
      backgroundColor={"white"}
      borderRadius={"12px"}
      textAlign={"left"}
    >
      <Heading as={"h2"} fontSize="24px" textAlign={"left"}>
        {isDefault
          ? t("amadeus.popularFlights.defaultTitle")
          : t("amadeus.popularFlights.title", { from: to.name })}
      </Heading>

      <Flex
        flexWrap={"wrap"}
        columnGap={"30px"}
        rowGap={2}
        justifyContent={"space-between"}
      >
        <Flex
          flex={{ base: "1 1 100%", lg: "1 1 calc(50% - 15px)" }}
          flexDirection={"column"}
          gap={2}
        >
          {top10Airports.slice(0, 5).map((toAirport, index) => {
            return (
              <Flex
                key={index}
                onClick={() => handleAirportClick(to, toAirport)}
                gap={"8px"}
                cursor={"pointer"}
                sx={{
                  ":hover": {
                    color: "#DD0000",
                  },
                  ":hover img": {
                    visibility: "visible",
                  },
                  "> img": {
                    visibility: "hidden",
                  },
                }}
              >
                <Text fontSize={"14px"} fontWeight={600}>
                  {to.name} to {toAirport.name} ({to.code} - {toAirport.code})
                </Text>
                <Image src={arrowRight} alt="arrow right" />
              </Flex>
            )
          })}
        </Flex>
        <Flex
          flex={{ base: "1 1 100%", lg: "1 1 calc(50% - 15px)" }}
          flexDirection={"column"}
          gap={2}
        >
          {top10Airports.slice(5, 10).map((toAirport, index) => (
            <Flex
              key={index}
              onClick={() => handleAirportClick(to, toAirport)}
              gap={"8px"}
              cursor={"pointer"}
              sx={{
                ":hover": {
                  color: "#DD0000",
                },
                ":hover img": {
                  visibility: "visible",
                },
                "> img": {
                  visibility: "hidden",
                },
              }}
            >
              <Text fontSize={"14px"} fontWeight={600}>
                {to.name} to {toAirport.name} ({to.code} - {toAirport.code})
              </Text>
              <Image src={arrowRight} alt="arrow right" />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PopularFlights
