import reportWebVitals from "./reportWebVitals"

// Libraries
import React, { useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import ReactDOM from "react-dom/client"
import { ChakraProvider } from "@chakra-ui/react"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom"

// Styles
import "./index.css"

// Services & Configs
import enTranslations from "./translations"
import chakraTheme from "./chakraTheme"
import "./services/api"
import { AuthProvider, useAuthContext } from "./services/auth"

// Components & Pages
import Loading from "./components/Loading"
import TermsAndConditions from "./pages/TermsAndConditions"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import AllAvailability from "./pages/AllAvailability"
import Amadeus from "./pages/Amadeus"
import Login from "./pages/Login"
import Register from "./pages/Register"
import Settings from "./pages/Settings"
import Alerts from "./pages/Alerts"
import ResetPassword from "./pages/ResetPassword"
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm"
import Pricing from "./pages/Pricing"
import CheckoutResult from "./pages/CheckoutResult"
import ToFromAustralia from "./pages/ToFromAustralia"
import FlightDetail from "./components/Flights/FlightDetail"
import AmadeusFlightDetail from "./components/AmadeusFlights/AmadeusFlightDetail"
import NotFound from "./pages/NotFound"
import PostModule from "./pages/PostModule"
import { HelmetProvider } from "react-helmet-async"
import ErrorFallback from "./components/ErrorFallback"
import Layout from "./components/Layout"

const resources = {
  en: {
    translation: enTranslations,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

const ProtectedRoute = ({ children }) => {
  const { user, error } = useAuthContext()

  if (!user && !error) {
    return <Loading />
  }

  return user ? children : <Navigate to="/" replace />
}

const AnonymousRoute = ({ children }) => {
  const { user, error } = useAuthContext()

  if (!user && !error) {
    return <Loading />
  }

  return user ? <Navigate to="/" /> : children
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (
          error.response?.data?.detail ===
          "Authentication credentials were not provided."
        ) {
          return false
        }
        if (failureCount < 2) {
          return true
        }
        return false
      },
    },
  },
})

const getInitialIsOpen = () => {
  const savedIsOpen = localStorage.getItem("isOpen")
  return savedIsOpen ? savedIsOpen === "true" : true
}

const AppLayout = () => {
  const [isOpen, setIsOpen] = useState(getInitialIsOpen)

  useEffect(() => {
    localStorage.setItem("isOpen", isOpen)
  }, [isOpen])

  const handleToggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Layout isOpen={isOpen} handleToggleMenu={handleToggleMenu}>
          <Outlet />
        </Layout>
      </ErrorBoundary>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <AllAvailability />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/login",
        element: (
          <AnonymousRoute>
            <Login />
          </AnonymousRoute>
        ),
      },
      {
        path: "/register",
        element: (
          <AnonymousRoute>
            <Register />
          </AnonymousRoute>
        ),
      },
      {
        path: "/settings",
        element: (
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        ),
      },
      {
        path: "/alerts",
        element: (
          <ProtectedRoute>
            <Alerts />
          </ProtectedRoute>
        ),
      },
      {
        path: "/reset-password",
        element: (
          <AnonymousRoute>
            <ResetPassword />
          </AnonymousRoute>
        ),
      },
      {
        path: "/reset-password-confirm/:uid/:token",
        element: (
          <AnonymousRoute>
            <ResetPasswordConfirm />
          </AnonymousRoute>
        ),
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/checkout_result",
        element: <CheckoutResult />,
      },
      {
        path: "/reward-flights",
        element: <AllAvailability />,
        children: [{ path: ":route", element: <FlightDetail /> }],
      },
      {
        path: "/flight-availability",
        element: <Amadeus />,
        children: [{ path: ":route", element: <AmadeusFlightDetail /> }],
      },
      {
        path: "/australian-reward-flights",
        element: <ToFromAustralia />,
      },
      {
        path: ":postUrl",
        element: <PostModule />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ChakraProvider theme={chakraTheme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
          {process.env.NODE_ENV === "development" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>
)

reportWebVitals()
