import { Fragment, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { format, differenceInCalendarDays, parseISO } from "date-fns"
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Show,
  Box,
  useOutsideClick,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Flex,
} from "@chakra-ui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"

import {
  TRAFFIC_LIGHT_COLORS,
  flightClassesMapping,
  ITEMS_PER_PAGE,
  COLORS,
} from "../../../constants/constants.js"

import Pagination from "../../Pagination.js"
import flightImages from "../../../flightImages.js"
import usePagination from "../../../hooks/usePagination.js"

import FlightsNotFound from "../../FlightsNotFound.js"
import ExpandableRow from "./ExpandableRow.js"
import NearlyFullPopover from "./NearlyFullPopover/Popover.js"
import CodeSharePopover from "./CodeSharePopover/Popover.js"
import LoadingAnimation from "../../LoadingAnimation.js"

const formatTime = (dateStr) => {
  const parsedDate = parseISO(dateStr)
  return format(parsedDate, "HH:mm")
}

const FlightsTable = ({
  data,
  status,
  isFetching,
  isError,
  error,
  originAirportsQuery,
}) => {
  const flights = data?.results || []
  const count = data?.count || 0

  const [expandedFlight, setExpandedFlight] = useState(null)
  const { page, onPrev, onNext, onCurrent } = usePagination()
  const ref = useRef()
  const { t } = useTranslation()

  useOutsideClick({
    ref,
    handler: () => setExpandedFlight(null),
  })

  const totalPages = Math.ceil((count || 0) / ITEMS_PER_PAGE)
  const handleOnNext = () => onNext(totalPages)

  if (isFetching) {
    return <LoadingAnimation />
  }

  if (isError) {
    if (error?.message === "API request limit reached") {
      return <Text p={2}>{error?.message}</Text>
    }
    return <Text p={2}>{t("table.error")}</Text>
  }

  if (status === "success" && flights.length === 0) {
    return <FlightsNotFound text={"table.emptyAmadeus"} />
  }

  return (
    <>
      <Table width="100%" ref={ref}>
        <Thead>
          <Tr
            boxShadow="0px 2px 8px rgba(20, 23, 37, 0.08)"
            fontSize={[10, 12]}
          >
            <Show above="lg">
              <Th textTransform="none" p={4} opacity={"0"}>
                ##
              </Th>
            </Show>
            <Th
              textTransform="none"
              px={2}
              py={4}
              w={{ base: "35%", lg: "20%" }}
            >
              {t("table.itinerary")}
            </Th>
            <Show above="lg">
              <Th textTransform="none" px={2} py={4}>
                {t("table.stops")}
              </Th>
              <Th textTransform="none" px={2} py={4} w={{ lg: "30%" }}>
                {t("table.unsoldFares")}
              </Th>
            </Show>
            <Show>
              <Th textTransform="none" px={2} py={4} w={{ lg: "30%" }}>
                {t("table.unsoldBusinessFirst")}
              </Th>
            </Show>
            <Th
              textTransform="none"
              textAlign="center"
              px={2}
              py={4}
              w={{ lg: "10%" }}
            >
              {t("table.upgradeLikelihood")}
            </Th>
            <Show above="lg">
              <Th></Th>
            </Show>
          </Tr>
        </Thead>
        <Tbody>
          {flights.map((flight) => {
            const { details } = flight

            let popoverType = "none"
            let aircraftCodes = []
            let flightDetail = null
            let likelihoodPlaneImages = []
            let popoverFlightsTexts = null

            const summaryPoints = flight.class_details.reduce(
              (acc, item) => ({
                ...acc,
                [item.designated_class]: {
                  name: t(
                    `table.${flightClassesMapping[item.designated_class]}`
                  ),
                  remaining_seats: item.remaining_seats,
                },
              }),
              {}
            )

            details.some((detail) => {
              const code = detail.aircraft_details.split(" ").at(-1)

              if (code.length === 4) {
                popoverType = "codeShare"
                flightDetail = detail
                aircraftCodes = details.map((det) => det.aircraft_details)
                likelihoodPlaneImages = details.map((det) => {
                  return {
                    image: flightImages(det.aircraft_code),
                    alt: `upgrade_${det.from_airport}_flight_to_${det.to_airport}`,
                  }
                })

                popoverFlightsTexts = details.map((det) => {
                  let from = originAirportsQuery.data.find(
                    (f) =>
                      f.code.toLowerCase() === det.from_airport.toLowerCase()
                  )

                  let to = originAirportsQuery.data.find(
                    (f) => f.code.toLowerCase() === det.to_airport.toLowerCase()
                  )

                  return {
                    from: {
                      name: from?.name.trim() || "Unknown",
                      code: from?.code || "Unknown",
                      fullName:
                        from?.name && from?.code
                          ? `${from?.name.trim()} (${from?.code})`
                          : "Unknown",
                    },
                    to: {
                      name: to?.name.trim() || "Unknown",
                      code: to?.code || "Unknown",
                      fullName: `${to?.name.trim()} (${to?.code})` || "Unknown",
                    },
                  }
                })

                return true
              }

              if (code.length < 4 && details.length === 1) {
                popoverType = "nearlyFull"
                flightDetail = detail
                aircraftCodes.push(detail.aircraft_details)
                likelihoodPlaneImages.push({
                  image: flightImages(detail.aircraft_code),
                  alt: `upgrade_${detail.from_airport}_flight_to_${detail.to_airport}`,
                })

                let from = originAirportsQuery.data.find(
                  (f) =>
                    f.code.toLowerCase() === detail.from_airport.toLowerCase()
                )

                let to = originAirportsQuery.data.find(
                  (f) =>
                    f.code.toLowerCase() === detail.to_airport.toLowerCase()
                )

                if (from && to) {
                  popoverFlightsTexts = [
                    {
                      from: {
                        name: from.name.trim(),
                        code: from.code,
                        fullName: `${from.name.trim()} (${from.code})`,
                      },
                      to: {
                        name: to.name.trim(),
                        code: to.code,
                        fullName: `${to.name.trim()} (${to.code})`,
                      },
                    },
                  ]
                }

                return true
              }

              return false
            })

            const planeImage =
              details.length >= 3 || !flightImages(details[0].aircraft_code)
                ? flightImages("group_3_plus")
                : flightImages(details[0].aircraft_code)

            const secondPlaneImage =
              details.length === 2 &&
              flightImages(details[0].aircraft_code) &&
              flightImages(details[1].aircraft_code)

            const departureDate = new Date(details[0].departure_date)
            const arrivalDate = new Date(
              details[details.length - 1].arrival_date
            )
            const diffInDays = differenceInCalendarDays(
              arrivalDate,
              departureDate
            )

            const isFlightExpanded = expandedFlight === flight

            const checkIfSameAirline = (details) => {
              const regex = /\((.*?)\)/

              // Extract the company names from each string in the array
              const companyNames = details.map((detail) => {
                const match = detail.aircraft_details.match(regex)

                return match ? match[1] : null
              })

              // Check if all extracted company names are the same
              return companyNames.every((name, _, arr) => name === arr[0])
            }

            let planeAltText = ""

            if (flight.origin && flight.destination) {
              planeAltText = `upgrade_${flight.origin.name}_flight_to_${flight.destination.name}`
            } else if (
              details[0].from_airport &&
              details[details.length - 1].to_airport
            ) {
              planeAltText = `upgrade_${details[0].from_airport}_flight_to_${
                details[details.length - 1].to_airport
              }`
            }

            const renderPopover = ({ popoverFlightsTexts, popoverType }) => {
              if (!popoverFlightsTexts) return null

              if (popoverType === "codeShare") {
                return (
                  <CodeSharePopover
                    planeImages={likelihoodPlaneImages}
                    details={details}
                    aircraftCodes={aircraftCodes}
                    popoverFlightsTexts={popoverFlightsTexts}
                    flightDetail={flightDetail}
                  />
                )
              }

              if (popoverType === "nearlyFull") {
                return (
                  <NearlyFullPopover
                    flight={flight}
                    planeImage={likelihoodPlaneImages}
                    details={details}
                    aircraftCode={aircraftCodes[0]}
                    popoverFlightsTexts={popoverFlightsTexts}
                    flightDetail={flightDetail}
                    summaryPoints={summaryPoints}
                  />
                )
              }

              return null
            }

            return (
              <Fragment key={flight.id}>
                <Tr
                  fontSize={[12, 14]}
                  position={isFlightExpanded ? "relative" : "initial"}
                  zIndex={isFlightExpanded ? 2 : 0}
                  backgroundColor={isFlightExpanded ? "#F7F7F9" : "#FFFFFF"}
                  transform={isFlightExpanded ? "translateZ(1px)" : "none"}
                  boxShadow={
                    isFlightExpanded
                      ? "0px -10px 18px 0px rgba(20, 23, 37, 0.13)"
                      : "none"
                  }
                  fontWeight="semibold"
                  onClick={() => {
                    setExpandedFlight(expandedFlight === flight ? null : flight)
                  }}
                  cursor="pointer"
                >
                  <Show above="lg">
                    <Td
                      p="8px"
                      width="50px"
                      position="relative"
                      border={isFlightExpanded ? "none" : ""}
                    >
                      <Image
                        width="100%"
                        src={planeImage}
                        alt={planeAltText}
                        margin="0 auto"
                        position="relative"
                        right="-4px"
                        zIndex={1}
                        top={secondPlaneImage ? "5px" : "0px"}
                        maxW={"32px"}
                      />
                      {secondPlaneImage && !checkIfSameAirline(details) && (
                        <Image
                          width="100%"
                          src={secondPlaneImage}
                          alt={planeAltText}
                          margin="0 auto"
                          position="relative"
                          right="4px"
                          bottom="18px"
                          zIndex={0}
                          maxW={"32px"}
                        />
                      )}
                    </Td>
                  </Show>
                  <Td p={2} border={isFlightExpanded ? "none" : ""}>
                    <Text>
                      {formatTime(flight.flight_start_date)} -{" "}
                      {formatTime(flight.flight_end_date)}
                      {diffInDays > 0 && <Text as="sup"> +{diffInDays}</Text>}
                    </Text>
                    <Text fontSize={12}>
                      {details
                        .map((conn) => conn.aircraft_details.split("(")[0])
                        .join(", ")}
                    </Text>
                  </Td>
                  <Show above="lg">
                    <Td
                      p={2}
                      border={isFlightExpanded ? "none" : ""}
                      fontSize={"12px"}
                    >
                      <Text>
                        {details.length === 1 ? "Direct" : details.length - 1}
                      </Text>
                      <Text fontSize={12} color={"#6A6E85"}>
                        {details
                          .slice(0, -1)
                          .map((conn) => conn.to_airport)
                          .join(", ")}
                      </Text>
                    </Td>
                    <Td p={2} border={isFlightExpanded ? "none" : ""}>
                      <Text color={COLORS.black} fontSize="xs">
                        {summaryPoints["Economy"]?.remaining_seats
                          .join(", ")
                          .replace(/,\s*$/, "")}
                        {summaryPoints["PremiumEconomy"]?.remaining_seats.length
                          ? ", "
                          : ""}
                        {summaryPoints["PremiumEconomy"]?.remaining_seats
                          .join(", ")
                          .replace(/,\s*$/, "")}
                      </Text>
                    </Td>
                  </Show>
                  <Show>
                    <Td p={2} border={isFlightExpanded ? "none" : ""}>
                      {summaryPoints["Business"] || summaryPoints["First"] ? (
                        <>
                          {summaryPoints["Business"] && (
                            <Text color={COLORS.black} fontSize="xs">
                              <Text as="span" color="#6A6E85" marginRight={2}>
                                Business:
                              </Text>
                              <Text as="span">
                                {`${summaryPoints["Business"]?.remaining_seats
                                  .join(", ")
                                  .replace(/,\s*$/, "")}`}
                              </Text>
                            </Text>
                          )}
                          {summaryPoints["First"] && (
                            <Text color={COLORS.black} fontSize="xs">
                              <Text as="span" color="#6A6E85" marginRight={8}>
                                First:
                              </Text>
                              <Text as="span">
                                {`${summaryPoints["First"]?.remaining_seats
                                  .join(", ")
                                  .replace(/,\s*$/, "")}`}
                              </Text>
                            </Text>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </Td>
                  </Show>
                  <Td>
                    <Popover placement={"bottom-end"} trigger="hover">
                      <PopoverTrigger>
                        <Flex
                          alignItems={"center"}
                          width={"100%"}
                          height={"22px"}
                        >
                          <Box
                            backgroundColor={
                              TRAFFIC_LIGHT_COLORS[flight.upgrade_likelihood]
                            }
                            width="10px"
                            height="10px"
                            borderRadius="50%"
                            margin="0 auto"
                          />
                        </Flex>
                      </PopoverTrigger>
                      <PopoverContent
                        width={"inherit"}
                        border={"none"}
                        boxShadow={"none"}
                        background={"transparent"}
                      >
                        {renderPopover({ popoverFlightsTexts, popoverType })}
                      </PopoverContent>
                    </Popover>
                  </Td>
                  <Show>
                    <Td px={{ base: "12px", lg: "24px" }}>
                      {isFlightExpanded ? (
                        <ChevronUpIcon boxSize={6} />
                      ) : (
                        <ChevronDownIcon boxSize={6} />
                      )}
                    </Td>
                  </Show>
                </Tr>
                {isFlightExpanded && (
                  <Tr
                    backgroundColor={"#F7F7F9"}
                    boxShadow="0px 15px 18px 0px rgba(20, 23, 37, 0.13)"
                    position={"relative"}
                    zIndex="1"
                  >
                    <Td colSpan={11} p={2} border={0}>
                      <ExpandableRow flight={flight} />
                    </Td>
                  </Tr>
                )}
              </Fragment>
            )
          })}
        </Tbody>
      </Table>

      <Pagination
        page={page}
        onCurrent={onCurrent}
        onNext={handleOnNext}
        onPrev={onPrev}
        totalPages={totalPages}
      />
    </>
  )
}

export default FlightsTable
