import { Select as ReactSelect, chakraComponents } from "chakra-react-select"
import { Flex, Image, Text } from "@chakra-ui/react"
import { useState, useCallback, useEffect, useMemo } from "react"
import selectArrowDown from "../img/select_arrow_down.svg"
import { debounce } from "lodash"
import { COLORS } from "../constants/constants"

export const selectChakraStyles = {
  control: (provided, state) => {
    const {
      selectProps: { overrideStyles = { control: {}, menu: {} } },
    } = state

    return {
      ...provided,
      _focusVisible: {
        ...provided._focusVisible,
        boxShadow: "none",
      },
      borderRadius: 12,
      zIndex: "0!important",
      ...overrideStyles.control,
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    background: "none",
    paddingInlineStart: "12px",
    paddingInlineEnd: "12px",
  }),
  groupHeading: (css) => ({
    ...css,
    cursor: "pointer",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "bold",
    padding: "0.4rem 0.8rem",
    _hover: {
      color: COLORS.red,
      backgroundColor: "#F7F7F9",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.4rem 1rem",
    color: `${COLORS.black}!important`,
    backgroundColor: `${COLORS.white}!important`,
    _hover: state.isDisabled
      ? {}
      : {
          color: `${COLORS.red}!important`,
          backgroundColor: "#F7F7F9!important",
        },
    cursor: state.isDisabled ? "not-allowed" : "pointer",
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: 12,
  }),
  menu: (provided) => ({
    ...provided,
  }),
}

const ValueContainer = ({ children, labelLeftText, ...rest }) => (
  <chakraComponents.ValueContainer {...rest}>
    <Flex align={"center"} m={0} p={0}>
      {typeof labelLeftText === "string" ? (
        <Text mr={1} color={"#6A6E85"}>
          {labelLeftText}
        </Text>
      ) : (
        labelLeftText
      )}
      <Flex w={"100%"} position={"relative"} align={"center"} m={0} p={0}>
        {children}
      </Flex>
    </Flex>
  </chakraComponents.ValueContainer>
)

const CustomDropdownIndicator = (value) => {
  return (
    <Image
      src={selectArrowDown}
      px={3}
      alt={`expand_to_reveal_reward_flights_to_${
        value?.label || "destination"
      }`}
    />
  )
}

const CustomGroupHeading = (
  { data: { label, value }, ...rest },
  handleOnChange
) => (
  <chakraComponents.GroupHeading {...rest}>
    <Text onClick={() => handleOnChange({ label, value })}>{label}</Text>
  </chakraComponents.GroupHeading>
)

export const GroupedSelect = ({
  labelLeftText = "",
  options,
  isSearchable = false,
  focused = false,
  showArrow = true,
  isFreePlan = true,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(props.value?.label || "")
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue)

  const searchable = isSearchable
    ? {
        inputValue,
        onInputChange: (value, { action }) =>
          action === "input-change" ? setInputValue(value) : null,
      }
    : {}

  const Container = useCallback(
    (props) => <ValueContainer {...props} labelLeftText={labelLeftText} />,
    [labelLeftText]
  )

  useEffect(() => {
    const handler = debounce(() => setDebouncedInputValue(inputValue), 300)
    handler()
    return () => handler.cancel()
  }, [inputValue])

  const filteredOptions = useMemo(() => {
    if (!isSearchable || debouncedInputValue.length <= 2) return options

    return options
      .map((group) => {
        const filteredGroupOptions = group.options.filter((o) =>
          o.label.toLowerCase().includes(debouncedInputValue.toLowerCase())
        )
        return filteredGroupOptions.length > 0
          ? { ...group, options: filteredGroupOptions }
          : null
      })
      .filter(Boolean)
  }, [debouncedInputValue, isSearchable, options])

  const finalOptions = useMemo(() => {
    if (!isFreePlan) return filteredOptions

    if (filteredOptions) {
      return filteredOptions.map((group) => ({
        ...group,
        options: group.options.map((option) => ({
          ...option,
          isDisabled: true,
        })),
      }))
    }

    return null
  }, [filteredOptions, isFreePlan])

  const customStyles = {
    ...selectChakraStyles,
    menu: (base) => ({
      ...base,
      display:
        isSearchable && filteredOptions.length === 0 ? "none" : base.display,
      zIndex: 999,
    }),
    control: (base) => ({
      ...base,
      _focusVisible: {
        ...base._focusVisible,
        boxShadow: "none",
      },
      borderRadius: 12,
      zIndex: "0!important",
      paddingRight: showArrow ? "0px" : "12px",
    }),
    valueContainer: (css) => ({
      ...css,
      input: { height: "auto" },
      paddingInlineStart: "12px",
      paddingInlineEnd: 0,
      "& > div > div > div:last-of-type": {
        width: "100%",
        position: "absolute",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "& > input": {
          opacity: 1,
        },
      },
    }),
  }

  const altText = props.value?.label
    ? `expand_to_reveal_reward_flights_to_${props.value?.label}`
    : "destination"

  const handleOnChange = (value) => {
    if (value.isDisabled) return
    setInputValue(value.label)
    props.onChange(value)
  }

  useEffect(() => {
    setInputValue(props.value?.label || "")
  }, [props.value])

  return (
    <ReactSelect
      menuPlacement="auto"
      focusBorderColor="black"
      autoFocus={focused}
      isSearchable={isSearchable}
      options={finalOptions}
      filterOption={() => true}
      components={{
        ValueContainer: Container,
        DropdownIndicator: showArrow
          ? () => CustomDropdownIndicator(altText)
          : null,
        IndicatorSeparator: null,
        GroupHeading: (children) =>
          CustomGroupHeading(children, handleOnChange),
      }}
      {...searchable}
      {...props}
      onFocus={() => setInputValue("")}
      onBlur={() => setInputValue("")}
      onChange={handleOnChange}
      chakraStyles={customStyles}
    />
  )
}
